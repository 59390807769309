
@import url('https://fonts.googleapis.com/css2?family=Londrina+Outline&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Londrina+Outline&family=Londrina+Solid:wght@100;300;400;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,1000;1,1000&display=swap');
a{
  text-decoration: none;
}
.mail:hover{
  color: white;
}
.josefin-sans-uniquifier {
  font-family: "Josefin Sans", sans-serif;

}
.open-sans-uniquifier {
  font-family: "Open Sans", sans-serif;
  
}
.mulish-uniquifier {
  font-family: "Mulish", sans-serif;
  font-optical-sizing: auto;
  font-weight: 1000;
  font-style: normal;
}

.londrina-outline-regular {
  font-family: "Londrina Outline", sans-serif;
}
.londrina-solid-thin {
  font-family: "Londrina Solid", sans-serif;

}

.responsive {
  width: 100%;
  height: auto;
}


@media only screen and (max-width: 768px) {
	.cardcontainer{
		background-image: url('../public/bg.png');
		text-align: center;
    padding-bottom: 10%;
		background-size: cover;
	}

	.image{
		display: none;
	}
	.responsivebold{
    font-weight: bold;
  }
}


/* fonts  */
.responsivefont-1{
  font-size: 5.3vw;
}
@media only screen and (max-width: 768px) {
  .responsivefont-1 {
    font-size: 10vw;
  }
}
.responsivefont0{
  font-size: 4.3vw;
}
@media only screen and (max-width: 768px) {
  .responsivefont0 {
    font-size: 8vw;
  }
}
.responsivefont{
  font-size: 3vw;
}
@media only screen and (max-width: 768px) {
  .responsivefont {
    font-size: 6.3vw;
  }
}
.responsivefont2{
  font-size: 2.8vw;
}
@media only screen and (max-width: 768px) {
  .responsivefont2 {
    font-size: 4.5vw;
  }
}
.responsivefont3{
  font-size: 2.5vw;
}
@media only screen and (max-width: 768px) {
  .responsivefont3 {
    font-size: 4vw;
  }
}
.responsivefont4{
  font-size: 2.2vw;
}
@media only screen and (max-width: 768px) {
  .responsivefont4 {
    font-size: 3.5vw;
  }
}
@media only screen and (max-width: 568px) {
  .responsivefont4 {
    font-size: 3vw;
  }
}
.responsivefont5{
  font-size: 1.8vw;
}
@media only screen and (max-width: 768px) {
  .responsivefont5 {
    font-size: 3vw;
  }
}
.responsivefont6{
  font-size: 1.5vw;
}
@media only screen and (max-width: 768px) {
  .responsivefont6 {
    font-size: 2.8vw;
  }
}
/* fonts end  */
.home{
    background-color: #FFFAE6;
  
}
.navbutton{
    border-radius: 8px;
    color: #542AEA;
    border-color: #542AEA;
    background-color: #FFFAE6;
}

.navbutton:hover{
    background-color: #542AEA;
    color: #FFFAE6;
    border-color: #FFFAE6;
}
.endbutton{
  background-color: #542AEA;
    color: white;
    border: 3.5px solid white;
    border-radius: 18px;

}
.endbutton:hover{
    color: #542AEA;
    border: 5px solid #542AEA;

    background-color: white;
}
.bluetext{
    color: #542AEA;
}
.blueback{
    background-color: #542AEA;
} 
.darkbluetext{
  color:#30199E;

} 
.darkblueback{
  background-color: #30199E;
} 
.yellowback{
  background-color: #FFFAE6;

}
.lightback{
  background-color: #F0EEFF;
}
.homebutton{
    background-color:#30199E;
    color: #FFFAE6;
    border: #30199E;
    border-radius: 15px;

}
.homebutton:hover{
   
    background-color: #542AEA;
}
.bgcolor{
    height:110px;
    background-color: #FFFAE6;
}

/* part3cards */
/* From Uiverse.io by gharsh11032000 */ 
.part3card-container {
    width: 300px;
    height: 300px;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }
  
  .part3card {
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }
  
  .part3card .front-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1)
  }
  
  .part3card .front-content p {
    font-size: 32px;
    font-weight: 700;
    opacity: 1;
    background: linear-gradient(-45deg, #542AEA 0%, #30199E 100% );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1)
  }
  
  .part3card .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    background: linear-gradient(-45deg, #542AEA 0%, #30199E 100% );
    color: #e8e8e8;
    padding: 20px;
    line-height: 1.5;
    border-radius: 5px;
    pointer-events: none;
    transform: translateX(-96%);
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  .part3card .content .heading {
    font-size: 32px;
    font-weight: 700;
  }
  
  .part3card:hover .content {
    transform: translateY(0);
  }
  
  .part3card:hover .front-content {
    transform: translateX(-30%);
  }
  
  .part3card:hover .front-content p {
    opacity: 0;
  }

/* card end  */

.qanimate {
  animation-name: fadeIn;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  opacity: 1;
}
.qanimate2 {
  animation-name: fadeIn;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  opacity: 1;
  animation-delay: 0.5s;
}
.qanimate3 {
  animation-name: fadeIn;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  opacity: 1;
  animation-delay: 1.5s;

}
.qanimate4 {
  animation-name: fadeIn;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  opacity: 1;
  animation-delay: 1s;

}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
@media only screen and (max-width: 768px){
    .responsiveque{
      width: 20vw;
    }
}
.imgshadow {

  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.486), 0 6px 20px 0 rgba(0, 0, 0, 0.623);
  text-align: center;
}
.gradblue{
  background-image: linear-gradient(
        to bottom,
        white 0%,
        white 5%,
        #F0EEFF 5%,
        #F0EEFF 90%,
        white 90%,
        white 100%
    );
    height: 100vh;
}
@media only screen and (max-width: 768px) {
  .gradblue {
    background-image: linear-gradient(
        to bottom,
        white 0%,
        white 10%,
        #F0EEFF 10%,
        #F0EEFF 90%,
        white 90%,
        white 100%
    );
    height: 45vh;
  }
}
.gradblue2{
  background-image: linear-gradient(
        to bottom,
        white 0%,
        white 15%,
        #F0EEFF 15%,
        #F0EEFF 90%,
        white 90%,
        white 100%
    );
    height: 100vh;
}
@media only screen and (max-width: 768px) {
  .gradblue2{
    background-image: linear-gradient(
          to bottom,
          white 0%,
          white 10%,
          #F0EEFF 10%,
          #F0EEFF 85%,
          white 85%,
          white 100%
      );
      height: 230vh;
  }
  .gradblue{
    height: 35vh;
  }
}
.margin20{
  margin-top: 20%;
}
@media only screen and (max-width: 576px){
  .gradblue2{
    
      height: 200vh;
  }
  
}
@media only screen and (max-width: 476px){
  .margin20{
    margin-top: 0%;
  }
}